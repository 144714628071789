<template>
  <div
    class="relative z-0 flex h-[310px] w-full flex-col rounded-2xl bg-[#FFFCE1] md:h-[230px] lg:h-[300px] xl:h-[410px] 2xl:h-[410px] 3xl:h-[470px] 5xl:h-[410px]"
  >
    <img
      src="/assets/icons/lightLike.svg"
      alt="Лайк"
      class="pointer-events-none absolute right-0 h-[100px] select-none xl:h-[145px] 2xl:h-[171px]"
    />
    <div class="z-20 mb-6 px-4 pt-6 xl:mb-8 xl:px-6">
      <div class="flex items-start gap-4 xl:h-32">
        <NuxtImg
          :src="reviewData.imageLink"
          alt="Фото клиента"
          preset="optimizedToWebp"
          class="pointer-events-none h-[64px] w-[64px] select-none rounded-2xl xl:h-[94px] xl:w-[94px]"
        />
        <div class="flex flex-col items-start justify-between gap-1">
          <div class="-z-20 break-words text-left text-sm font-bold xl:h-16 xl:text-2xl 5xl:w-60">
            {{ reviewData.title.name }}
          </div>
          <div class="flex flex-col gap-1">
            <div class="text-left font-manrope text-sm text-dnd_dark_grey xl:text-base">
              {{ reviewData.name }}
            </div>
            <div class="flex">
              <UiIcon
                v-for="count in starCount"
                :key="count"
                name="star"
                class="lg:hidden"
                size="sm"
              />
              <UiIcon
                v-for="count in starCount"
                :key="count"
                name="star"
                class="hidden lg:block"
                size="md"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-start justify-between gap-4 px-4 xl:h-48 xl:px-6 5xl:gap-8">
      <div class="z-20 flex items-start justify-start">
        <div
          class="text-left font-manrope text-sm font-bold xl:text-base"
          v-html="reviewData.body"
        />
      </div>
      <div class="flex">
        <p class="font-manrope text-xs font-semibold text-dnd_dark_grey xl:text-sm">
          {{ formattedDate }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs';

const props = defineProps({
  reviewData: {
    type: Object,
    required: true,
  },
});

const starCount = 5;

const formattedDate = computed(() => {
  return dayjs(props.reviewData.updatedAt).format('DD.MM.YYYY');
});
</script>
