<template>
  <div
    v-if="data"
    class="flex flex-col gap-6 xl:gap-10"
  >
    <div class="flex flex-row items-center justify-between">
      <h2 class="text-2xl font-bold xl:text-[40px]">Отзывы клиентов</h2>
      <div class="flex gap-2 lg:hidden">
        <img
          src="assets/icons/leftArrow.svg"
          alt="leftArrow"
          @click="prevSlide"
        />
        <div>{{ currentSlide + 1 }}/{{ reviewList.length }}</div>
        <img
          src="assets/icons/rightArrow.svg"
          alt="rightArrow"
          @click="nextSlide"
        />
      </div>
    </div>
    <div class="flex-row gap-8 xl:flex xl:items-center">
      <button
        class="hidden h-12 min-h-[56px] w-12 min-w-[56px] rounded-full bg-dnd_yellow text-white xl:block"
        @click="prevSlide"
      >
        <p class="font-manrope text-2xl text-black">&lt;</p>
      </button>
      <CCarousel
        ref="myCarousel"
        :items-to-show="1"
        :breakpoints="breakpoints"
        @update:model-value="updateCurrentSlide"
      >
        <CSlide
          v-for="(item, index) in reviewList"
          :key="index"
        >
          <div class="flex px-2 3xl:px-4">
            <CardReviewSlider :review-data="item" />
          </div>
        </CSlide>
      </CCarousel>
      <button
        class="hidden h-12 min-h-[56px] w-12 min-w-[56px] rounded-full bg-dnd_yellow text-white xl:block"
        @click="nextSlide"
      >
        <p class="font-manrope text-2xl text-black">&gt;</p>
      </button>
    </div>
  </div>
  <div v-else>
    <UiIsLoading />
  </div>
</template>

<script setup>
const { $api } = useNuxtApp();

const isMobile = ref(false);
const titles = [
  'Простой вопрос лучшие',
  'МФО которое выручает',
  'Огромная благодарность!',
  'Рекомендую всем!',
];

const breakpoints = {
  1024: {
    itemsToShow: 2.4,
  },
  1640: {
    itemsToShow: 3,
    itemsToScroll: 1,
  },
};

const reviewList = ref([]);
const currentSlide = ref(0);
const myCarousel = ref(null);

const { data } = await useAsyncData(
  'fetchReview',
  async () => {
    try {
      const { data } = await $api.web().getAllReviews();
      if (!data.data.length) throw new Error('No data');
      reviewList.value = data.data.map((review, index) => ({
        ...review,
        title: { name: titles[index % titles.length] },
      }));
      return true;
    } catch (err) {
      return false;
    }
  },
  {
    server: false,
    lazy: true,
  },
);

// TODO: its neeed refactoring
const nextSlide = () => {
  if (currentSlide.value === 0 && !isMobile.value) {
    myCarousel.value.slideTo(2);
  } else {
    myCarousel.value.next();
  }
};

const prevSlide = () => {
  if (myCarousel.value.data.maxSlide.value === currentSlide.value && !isMobile.value) {
    myCarousel.value.slideTo(myCarousel.value.data.maxSlide.value - 2);
  } else {
    myCarousel.value.prev();
  }
};

const updateCurrentSlide = (currentSlideIndex) => {
  currentSlide.value = currentSlideIndex;
};

const checkIsMobile = () => {
  isMobile.value = window.innerWidth < 1024;
};

onMounted(() => {
  checkIsMobile();
});
</script>
